var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    [
                      _vm._v(" Summary"),
                      _c(
                        "router-link",
                        {
                          staticClass: "link-icon",
                          attrs: {
                            to: {
                              name: "task-detail-edit",
                              params: { id: _vm.taskAttribute.TaskAttributeID }
                            }
                          }
                        },
                        [_c("b-icon-pencil-square")],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-card-body", [
                    _c(
                      "table",
                      { staticClass: "table table-sm table-striped" },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Reference")
                            ]),
                            _c("td", { staticClass: "w-100" }, [
                              _vm._v(
                                _vm._s(_vm.taskAttribute.TaskReference) + " "
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Owner")
                            ]),
                            _c("td", { staticClass: "w-100" }, [
                              _vm._v(
                                _vm._s(_vm.taskAttribute.User.FullName) + " ("
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "mailto:" + _vm.taskAttribute.User.EMail
                                  }
                                },
                                [_vm._v(_vm._s(_vm.taskAttribute.User.EMail))]
                              ),
                              _vm._v(")")
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Sub Provider")
                            ]),
                            _c("td", { staticClass: "w-100" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.taskAttribute.SubProvider.Description
                                ) + " "
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Status")
                            ]),
                            _c("td", { staticClass: "w-100" }, [
                              _vm._v(_vm._s(_vm.taskAttribute.Status) + " ")
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Progress Description")
                            ]),
                            _c(
                              "td",
                              { staticClass: "w-100" },
                              [
                                _vm.taskAttribute.ProgressDescription
                                  ? [
                                      _vm._v(
                                        _vm._s(
                                          _vm.taskAttribute.ProgressDescription
                                        )
                                      )
                                    ]
                                  : [_vm._v("-")]
                              ],
                              2
                            )
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Due")
                            ]),
                            _c("td", { staticClass: "w-100" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.dateFromNow(
                                    _vm.taskAttribute.CompletionDateTime
                                  )
                                ) +
                                  " (" +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.taskAttribute.CompletionDateTime
                                    )
                                  ) +
                                  ")"
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Release formats:")
                            ]),
                            _c("td", { staticClass: "w-100" }, [
                              _vm.releaseFormats.length > 0
                                ? _c(
                                    "ul",
                                    _vm._l(_vm.releaseFormats, function(
                                      format
                                    ) {
                                      return _c(
                                        "li",
                                        [
                                          _vm._v(
                                            " " + _vm._s(format.Value) + " "
                                          ),
                                          !format.Album
                                            ? _c("em", [
                                                _vm._v("(No album created yet)")
                                              ])
                                            : _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "release-detail",
                                                      params: {
                                                        id: format.Album.AlbumID
                                                      }
                                                    }
                                                  }
                                                },
                                                [_vm._v("open")]
                                              )
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _c("p", [_vm._v("-")])
                            ])
                          ])
                        ])
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Actions" } },
                [
                  _vm.nextActions.length == 0 && _vm.laterActions.length == 0
                    ? _c("p", [_vm._v("No suggested actions available")])
                    : _vm._e(),
                  _vm.nextActions.length > 0
                    ? [
                        _c("h6", [_vm._v("Up next:")]),
                        _c(
                          "ul",
                          _vm._l(_vm.nextActions, function(action, index) {
                            return _c(
                              "li",
                              [
                                _vm._v(_vm._s(action.description) + " "),
                                action.infoList
                                  ? _c(
                                      "strong",
                                      {
                                        attrs: { id: "nextActionItem-" + index }
                                      },
                                      [_vm._v("(info)")]
                                    )
                                  : _vm._e(),
                                action.infoList
                                  ? _c(
                                      "b-popover",
                                      {
                                        attrs: {
                                          target: "nextActionItem-" + index,
                                          triggers: "hover",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c(
                                          "ul",
                                          _vm._l(action.infoList, function(
                                            info
                                          ) {
                                            return _c("li", [
                                              _vm._v(_vm._s(info))
                                            ])
                                          }),
                                          0
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ]
                    : _vm._e(),
                  _vm.laterActions.length > 0
                    ? [
                        _c("h6", [_vm._v("Later:")]),
                        _c(
                          "ul",
                          _vm._l(_vm.laterActions, function(action) {
                            return _c("li", [
                              _vm._v(_vm._s(action.description))
                            ])
                          }),
                          0
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Quick edit" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "groupNotes",
                        label: "Shared notes",
                        "label-for": "quickNotes"
                      }
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "quickNotes",
                          rows: "3",
                          "max-rows": "20"
                        },
                        model: {
                          value: _vm.sharedNotes,
                          callback: function($$v) {
                            _vm.sharedNotes = $$v
                          },
                          expression: "sharedNotes"
                        }
                      })
                    ],
                    1
                  ),
                  _c("b-button", { on: { click: _vm.saveSharedNotes } }, [
                    _vm._v("Save")
                  ]),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mt-4",
                      attrs: {
                        id: "groupProgresss",
                        label: "Progress Description",
                        "label-for": "quickProgress"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: "quickProgress", type: "text" },
                        model: {
                          value: _vm.progressDescription,
                          callback: function($$v) {
                            _vm.progressDescription = $$v
                          },
                          expression: "progressDescription"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    { on: { click: _vm.saveProgressDescription } },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6", lg: "4" } },
            [
              _c("b-card", { attrs: { header: "Sub Tasks" } }, [
                _vm.activeTasks.length > 0
                  ? _c("table", { staticClass: "table" }, [
                      _c(
                        "tbody",
                        _vm._l(_vm.activeTasks, function(task) {
                          return _c("tr", [
                            _c(
                              "td",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "task-detail-overview",
                                        params: { id: task.TaskAttributeID }
                                      }
                                    }
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(_vm._s(task.Description))
                                    ])
                                  ]
                                ),
                                _c("br"),
                                _c("em", [
                                  _vm._v(_vm._s(task.ProgressDescription))
                                ])
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                _vm._v(" " + _vm._s(task.Status)),
                                _c("br"),
                                _vm._v(_vm._s(task.User.FullName)),
                                _c("br"),
                                task.CompletionDateTime
                                  ? [
                                      _vm._v(
                                        "Due " +
                                          _vm._s(
                                            _vm.dateFromNow(
                                              task.CompletionDateTime
                                            )
                                          ) +
                                          " (" +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              task.CompletionDateTime
                                            )
                                          ) +
                                          ")"
                                      )
                                    ]
                                  : task.MinimumCompletionDateTime
                                  ? [
                                      _vm._v(
                                        "Minimum due date " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              task.MinimumCompletionDateTime
                                            )
                                          ) +
                                          " (*)"
                                      )
                                    ]
                                  : task.CompletionMinimumIntervalFromParent
                                  ? [
                                      _vm._v(
                                        "Minimum " +
                                          _vm._s(
                                            task.CompletionMinimumIntervalFromParent
                                          ) +
                                          " (*)"
                                      )
                                    ]
                                  : [_vm._v("Due date not set")]
                              ],
                              2
                            )
                          ])
                        }),
                        0
                      )
                    ])
                  : _c("p", [
                      _vm._v(
                        "No subtasks created yet. To add subtasks, go to the 'Tasks' tab"
                      )
                    ]),
                _c("p", { staticClass: "mt-3 mb-0" }, [
                  _c("em", [_vm._v("(*) No due date set")])
                ])
              ])
            ],
            1
          ),
          Object.entries(_vm.taskAttribute.attributes).length != 0
            ? _c(
                "b-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c(
                    "b-card",
                    { attrs: { header: "Album information" } },
                    [
                      !_vm.albumsCreated
                        ? _c("p", [
                            _c("em", [
                              _vm._v(
                                "To enter album information, please use the 'Attributes' tab"
                              )
                            ])
                          ])
                        : _c("p", [
                            _c("em", [_vm._v("Albums have been created")])
                          ]),
                      _c(
                        "table",
                        { staticClass: "table table-sm table-striped mb-0" },
                        [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v("Title Language:")
                              ]),
                              _c("td", { staticClass: "w-100" }, [
                                _vm._v(_vm._s(_vm.albumData.TitleLanguageID))
                              ])
                            ]),
                            _c("tr", [
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v("Title:")
                              ]),
                              _c("td", { staticClass: "w-100" }, [
                                _vm._v(_vm._s(_vm.albumData.Title))
                              ])
                            ]),
                            _c("tr", [
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v("Version Title:")
                              ]),
                              _c("td", { staticClass: "w-1000" }, [
                                _vm._v(_vm._s(_vm.albumData.VersionTitle) + " ")
                              ])
                            ]),
                            _c("tr", [
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v("Attribution:")
                              ]),
                              _c("td", { staticClass: "w-1000" }, [
                                _vm._v(_vm._s(_vm.albumData.Attribution) + " ")
                              ])
                            ]),
                            _c("tr", [
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v("Catalog number:")
                              ]),
                              _c("td", { staticClass: "w-1000" }, [
                                _vm._v(_vm._s(_vm.albumData.CatalogNr) + " ")
                              ])
                            ]),
                            _c("tr", [
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v("Origin country:")
                              ]),
                              _c("td", { staticClass: "w-1000" }, [
                                _vm._v(
                                  _vm._s(_vm.albumData.OriginCountry) + " "
                                )
                              ])
                            ]),
                            _c("tr", [
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v("P-Line:")
                              ]),
                              _c("td", { staticClass: "w-1000" }, [
                                _vm._v(_vm._s(_vm.albumData.PLine) + " ")
                              ])
                            ]),
                            _c("tr", [
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v("C-Line:")
                              ]),
                              _c("td", { staticClass: "w-1000" }, [
                                _vm._v(_vm._s(_vm.albumData.CLine) + " ")
                              ])
                            ]),
                            _c("tr", [
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v("Release Date:")
                              ]),
                              _c("td", { staticClass: "w-1000" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.albumData.ReleaseDate
                                        ? new Date(
                                            _vm.albumData.ReleaseDate * 1000
                                          )
                                        : ""
                                    )
                                  ) + " "
                                )
                              ])
                            ]),
                            _c("tr", [
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v("Top Level:")
                              ]),
                              _c("td", { staticClass: "w-1000" }, [
                                _vm._v(_vm._s(_vm.albumData.TopLevel) + " ")
                              ])
                            ]),
                            _c("tr", [
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v("Recording Version:")
                              ]),
                              _c("td", { staticClass: "w-1000" }, [
                                _vm._v(
                                  _vm._s(_vm.albumData.RecordingVersion) + " "
                                )
                              ])
                            ]),
                            _c("tr", [
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v("Main Genre:")
                              ]),
                              _c("td", { staticClass: "w-1000" }, [
                                _vm._v(_vm._s(_vm.albumData.MainGenre) + " ")
                              ])
                            ]),
                            _c("tr", [
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v("Release formats:")
                              ]),
                              _c("td", { staticClass: "w-100" }, [
                                _vm.assigningUpc
                                  ? _c(
                                      "p",
                                      [
                                        _c("b-spinner"),
                                        _c("span", [_vm._v("Assigning UPC")])
                                      ],
                                      1
                                    )
                                  : _vm.releaseFormats.length > 0
                                  ? _c(
                                      "ul",
                                      _vm._l(_vm.releaseFormats, function(
                                        format
                                      ) {
                                        return _c(
                                          "li",
                                          { staticClass: "mb-1" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(format.Value) + " "
                                            ),
                                            !format.Album
                                              ? _c("em", [
                                                  _vm._v(
                                                    "(No album created yet)"
                                                  )
                                                ])
                                              : [
                                                  format.Album &&
                                                  format.Album.UPCorEAN &&
                                                  !format.Album.UPCorEAN.startsWith(
                                                    "952"
                                                  )
                                                    ? _c("em", [
                                                        _vm._v(
                                                          "(UPC: " +
                                                            _vm._s(
                                                              format.Album
                                                                .UPCorEAN
                                                            ) +
                                                            ")"
                                                        )
                                                      ])
                                                    : _c(
                                                        "b-button",
                                                        {
                                                          attrs: { size: "sm" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.assignUpc(
                                                                format.Album
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("Assign UPC")]
                                                      )
                                                ]
                                          ],
                                          2
                                        )
                                      }),
                                      0
                                    )
                                  : _c("p", [_vm._v("-")])
                              ])
                            ])
                          ])
                        ]
                      ),
                      _vm.creatingAlbums
                        ? _c(
                            "div",
                            [
                              _c("b-spinner"),
                              _c("span", [
                                _vm._v(
                                  "Creating Albums " +
                                    _vm._s(_vm.creatingAlbumsProgress)
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.readyToCreateAlbums
                        ? _c("b-button", { on: { click: _vm.createAlbums } }, [
                            _vm._v("Create Album per Release Format")
                          ])
                        : !_vm.albumsCreated
                        ? _c("p", [
                            _vm._v(
                              "Finish entering all album details, and make sure the connected basket has all tracks needed to enable creating an album per Release Format."
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6", lg: "4" } },
            [
              _c(
                "b-card",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _vm._v("Timeline"),
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "timeline-menu float-right",
                              attrs: {
                                "toggle-class": "timeline-menu-toggle",
                                right: ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "button-content",
                                  fn: function() {
                                    return [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "bi bi-three-dots-vertical",
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "16",
                                            height: "16",
                                            fill: "currentColor",
                                            viewBox: "0 0 16 16"
                                          }
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d:
                                                "M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _vm.tasks.some(function(task) {
                                return !["Completed", "Resolved"].includes(
                                  task.Status
                                )
                              })
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { disabled: _vm.subTasksLoading },
                                      on: {
                                        click: function($event) {
                                          return _vm.setStatusAll(
                                            _vm.tasks,
                                            "Completed"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Set all Completed")]
                                  )
                                : _vm._e(),
                              _vm.tasks.some(function(task) {
                                return !["Waiting"].includes(task.Status)
                              })
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { disabled: _vm.subTasksLoading },
                                      on: {
                                        click: function($event) {
                                          return _vm.setStatusAll(
                                            _vm.tasks,
                                            "Waiting"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Set all Waiting")]
                                  )
                                : _vm._e(),
                              _vm.taskAttribute.Status !=
                                "Waiting for Manufacturing" ||
                              (_vm.tasks.some(function(task) {
                                return task.Description.endsWith(
                                  " - Manufacturing"
                                )
                              }) &&
                                _vm.tasks.some(function(task) {
                                  return (
                                    !task.Description.endsWith(
                                      " - Manufacturing"
                                    ) &&
                                    !["Completed", "Resolved"].includes(
                                      task.Status
                                    )
                                  )
                                }))
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { disabled: _vm.subTasksLoading },
                                      on: {
                                        click: function($event) {
                                          return _vm.setStatusWaitingForManufacturing()
                                        }
                                      }
                                    },
                                    [_vm._v("Set ready for Manufacturing")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _vm.subTasksLoading
                            ? _c("b-spinner", [_vm._v("Loading tasks")])
                            : _vm._e(),
                          _vm.timelineTasks.length > 0
                            ? _c(
                                "b-list-group",
                                _vm._l(_vm.tasks, function(task) {
                                  return _c(
                                    "b-list-group-item",
                                    {
                                      key: task.TaskAttributeID,
                                      staticClass: "condensed",
                                      attrs: {
                                        variant: _vm.taskVariant(task),
                                        id:
                                          "timeline-popover-" +
                                          task.TaskAttributeID
                                      }
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "task-detail-overview",
                                              params: {
                                                id: task.TaskAttributeID
                                              }
                                            }
                                          }
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(task.Description))
                                          ])
                                        ]
                                      ),
                                      !["Completed", "Resolved"].includes(
                                        task.Status
                                      )
                                        ? _c(
                                            "b-dropdown",
                                            {
                                              staticClass:
                                                "timeline-menu float-right",
                                              attrs: {
                                                "toggle-class":
                                                  "timeline-menu-toggle",
                                                right: ""
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "button-content",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "svg",
                                                          {
                                                            staticClass:
                                                              "bi bi-three-dots-vertical",
                                                            attrs: {
                                                              xmlns:
                                                                "http://www.w3.org/2000/svg",
                                                              width: "16",
                                                              height: "16",
                                                              fill:
                                                                "currentColor",
                                                              viewBox:
                                                                "0 0 16 16"
                                                            }
                                                          },
                                                          [
                                                            _c("path", {
                                                              attrs: {
                                                                d:
                                                                  "M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c(
                                                "b-dropdown-item",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.setStatus(
                                                        task,
                                                        "Completed"
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Set Completed")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !["Completed", "Resolved"].includes(
                                        task.Status
                                      )
                                        ? _c(
                                            "span",
                                            { staticClass: "float-right" },
                                            [
                                              task.CompletionDateTime
                                                ? [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("dateFormat")(
                                                            task.CompletionDateTime
                                                          )
                                                        )
                                                    )
                                                  ]
                                                : task.MinimumCompletionDateTime
                                                ? [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("dateFormat")(
                                                          task.MinimumCompletionDateTime
                                                        )
                                                      ) + " (*)"
                                                    )
                                                  ]
                                                : task.CompletionMinimumIntervalFromParent
                                                ? [
                                                    _vm._v(
                                                      _vm._s(
                                                        task.CompletionMinimumIntervalFromParent
                                                      )
                                                    )
                                                  ]
                                                : [_vm._v("-")]
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-popover",
                                        {
                                          attrs: {
                                            target:
                                              "timeline-popover-" +
                                              task.TaskAttributeID,
                                            triggers: "hover",
                                            placement: "left"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "Status: " + _vm._s(task.Status)
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "Owner: " +
                                              _vm._s(task.User.FullName)
                                          ),
                                          _c("br"),
                                          task.ProgressDescription
                                            ? [
                                                _vm._v(
                                                  "Progress: " +
                                                    _vm._s(
                                                      task.ProgressDescription
                                                    )
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("p", { staticClass: "mt-3 mb-0" }, [
                    _c("em", [_vm._v("(*) No due date set")])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      false
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-card", { attrs: { header: "Album data" } }, [
                    _c("pre", [_vm._v(_vm._s(_vm.releaseFormats))]),
                    _c("pre", [_vm._v(_vm._s(_vm.albumData))])
                  ])
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-card", { attrs: { header: "TaskAttribute" } }, [
                    _c("pre", [_vm._v(_vm._s(_vm.taskAttribute))])
                  ])
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-card", { attrs: { header: "Attribute Data" } }, [
                    _c("pre", [
                      _vm._v(_vm._s(_vm.taskAttribute.attributeTypes))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }