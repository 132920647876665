<template lang="pug">

b-container(fluid)
  b-row 
    b-col(cols=12, md=6, lg=4)
      b-card(no-body)
        b-card-header 
          | Summary
          router-link.link-icon(:to="{ name: 'task-detail-edit', params: { id: taskAttribute.TaskAttributeID } }")
            b-icon-pencil-square

        b-card-body
          table.table.table-sm.table-striped
            tbody
              tr
                td.text-nowrap Reference
                td.w-100 {{ taskAttribute.TaskReference }} 

              tr
                td.text-nowrap Owner
                td.w-100 {{ taskAttribute.User.FullName }} 
                  | (
                  a(:href="`mailto:${taskAttribute.User.EMail}`") {{ taskAttribute.User.EMail }}
                  | )

              tr
                td.text-nowrap Sub Provider
                td.w-100 {{ taskAttribute.SubProvider.Description }} 

              tr
                td.text-nowrap Status
                td.w-100 {{ taskAttribute.Status }} 

              tr
                td.text-nowrap Progress Description
                td.w-100 
                  template(v-if='taskAttribute.ProgressDescription') {{ taskAttribute.ProgressDescription }}
                  template(v-else) -

              tr
                td.text-nowrap Due
                td.w-100 {{ dateFromNow(taskAttribute.CompletionDateTime) }} ({{ taskAttribute.CompletionDateTime|dateFormat }})

              tr
                td.text-nowrap Release formats:
                td.w-100
                  ul(v-if='releaseFormats.length > 0')
                    li(v-for='format in releaseFormats') 
                      | {{ format.Value }}&nbsp;
                      em(v-if='!format.Album') (No album created yet)
                      router-link(
                        v-else
                        :to="{ name: 'release-detail', params: { id: format.Album.AlbumID } }"
                      ) open
                  p(v-else) -

    b-col(cols=12, md=6, lg=4)
      b-card(header='Actions')
        p(v-if='nextActions.length == 0 && laterActions.length == 0')
          | No suggested actions available

        template(v-if='nextActions.length > 0')
          h6 Up next:
          ul
            li(
              v-for='action, index in nextActions'
            ) {{ action.description }} 
              strong(
                v-if='action.infoList' 
                :id="`nextActionItem-${index}`"
              ) (info)
              b-popover(
                v-if='action.infoList' 
                :target="`nextActionItem-${index}`"
                triggers="hover"
                placement="top"
              )
                ul
                  li(v-for='info in action.infoList') {{ info }}

        template(v-if='laterActions.length > 0')
          h6 Later:
          ul
            li(v-for='action in laterActions') {{ action.description }}

    b-col(cols=12, md=6, lg=4)
      b-card(header='Quick edit')
        b-form-group(
          id="groupNotes"
          label="Shared notes"
          label-for="quickNotes"
        )
          b-form-textarea(
            v-model="sharedNotes"
            id="quickNotes"
            rows="3"
            max-rows="20"
          )

        b-button(@click='saveSharedNotes') Save

        b-form-group.mt-4(
          id="groupProgresss"
          label="Progress Description"
          label-for="quickProgress"
        )
          b-form-input(
            id="quickProgress"
            type="text"
            v-model="progressDescription"
          )
        b-button(@click='saveProgressDescription') Save

    b-col(cols=12, md=6, lg=4)
      b-card(header='Sub Tasks')
        table.table(v-if='activeTasks.length > 0')
          tbody
            tr(v-for='task in activeTasks')
              td
                router-link(
                  :to=`{ 
                    name: 'task-detail-overview', 
                    params: { id: task.TaskAttributeID } 
                  }`
                ) 
                  strong {{ task.Description }}

                br
                em {{ task.ProgressDescription }}

              td 
                | {{ task.Status  }}
                br
                | {{ task.User.FullName }}
                br
                template(v-if='task.CompletionDateTime') Due {{ dateFromNow(task.CompletionDateTime) }} ({{ task.CompletionDateTime|dateFormat }})
                template(v-else-if='task.MinimumCompletionDateTime')
                  | Minimum due date {{ task.MinimumCompletionDateTime|dateFormat }}&nbsp;(*)
                template(v-else-if='task.CompletionMinimumIntervalFromParent')
                  | Minimum {{ task.CompletionMinimumIntervalFromParent }}&nbsp;(*)
                template(v-else) Due date not set

        p(v-else) No subtasks created yet. To add subtasks, go to the 'Tasks' tab
        p.mt-3.mb-0 
          em (*) No due date set

    b-col(cols=12, md=6, lg=4, v-if='Object.entries(taskAttribute.attributes).length != 0')
      b-card(:header="'Album information'")
        p(v-if='!albumsCreated')
          em To enter album information, please use the 'Attributes' tab
        p(v-else)
          em Albums have been created

        table.table.table-sm.table-striped.mb-0
          tbody
            tr
              td.text-nowrap Title Language:
              td.w-100 {{ albumData.TitleLanguageID }}
                
            tr
              td.text-nowrap Title:
              td.w-100 {{ albumData.Title }}
                
            tr
              td.text-nowrap Version Title:
              td.w-1000 {{ albumData.VersionTitle }} 
                
            tr
              td.text-nowrap Attribution:
              td.w-1000 {{ albumData.Attribution }} 
                
            tr
              td.text-nowrap Catalog number:
              td.w-1000 {{ albumData.CatalogNr }} 
                
            tr
              td.text-nowrap Origin country:
              td.w-1000 {{ albumData.OriginCountry }} 
                
            tr
              td.text-nowrap P-Line:
              td.w-1000 {{ albumData.PLine }} 
                
            tr
              td.text-nowrap C-Line:
              td.w-1000 {{ albumData.CLine }} 
                
            tr
              td.text-nowrap Release Date:
              td.w-1000 {{ (albumData.ReleaseDate ? new Date(albumData.ReleaseDate * 1000) : '')|dateFormat }} 
                
            tr
              td.text-nowrap Top Level:
              td.w-1000 {{ albumData.TopLevel }} 
                
            tr
              td.text-nowrap Recording Version:
              td.w-1000 {{ albumData.RecordingVersion }} 
                
            tr
              td.text-nowrap Main Genre:
              td.w-1000 {{ albumData.MainGenre }} 
                
            tr
              td.text-nowrap Release formats:
              td.w-100
                p(v-if='assigningUpc')
                  b-spinner
                  span Assigning UPC
                ul(v-else-if='releaseFormats.length > 0' )
                  li.mb-1(v-for='format in releaseFormats') 
                    | {{ format.Value }}&nbsp;
                    em(v-if='!format.Album') (No album created yet)
                    template(v-else)
                      em(v-if="format.Album && format.Album.UPCorEAN && !format.Album.UPCorEAN.startsWith('952')") (UPC: {{ format.Album.UPCorEAN }})
                      b-button(
                        v-else
                        size="sm"
                        @click='assignUpc(format.Album)'
                      ) Assign UPC

                p(v-else) -

        div(v-if='creatingAlbums')
          b-spinner
          span Creating Albums {{ creatingAlbumsProgress }}
        b-button(
          v-if='readyToCreateAlbums'
          @click='createAlbums'
        ) Create Album per Release Format
        p(v-else-if='!albumsCreated')
          | Finish entering all album details, and make sure the connected basket has all tracks needed to enable creating an album per Release Format.


    b-col(cols=12, md=6, lg=4)
      b-card
        template(#header)
          | Timeline

          b-dropdown.timeline-menu.float-right(
              
              toggle-class="timeline-menu-toggle"
              right
            )
              template(#button-content)
                svg(
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16" 
                  fill="currentColor" 
                  class="bi bi-three-dots-vertical" 
                  viewBox="0 0 16 16"
                )
                  path(
                    d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                  )

              b-dropdown-item(
                @click="setStatusAll(tasks, 'Completed')"
                v-if="tasks.some(task => !['Completed', 'Resolved'].includes(task.Status))"
                :disabled="subTasksLoading"
              ) Set all Completed

              b-dropdown-item(
                @click="setStatusAll(tasks, 'Waiting')"
                v-if="tasks.some(task => !['Waiting'].includes(task.Status))"
                :disabled="subTasksLoading"
              ) Set all Waiting

              b-dropdown-item(
                @click="setStatusWaitingForManufacturing()"
                v-if=`
                  taskAttribute.Status != 'Waiting for Manufacturing' || (
                    tasks.some(task => task.Description.endsWith(' - Manufacturing')) && 
                    tasks.some(task => !task.Description.endsWith(' - Manufacturing') && !['Completed', 'Resolved'].includes(task.Status))
                  )
                `
                :disabled="subTasksLoading"
              ) Set ready for Manufacturing

        template(#default)
          b-spinner(v-if='subTasksLoading') Loading tasks
          b-list-group(v-if='timelineTasks.length > 0')
            b-list-group-item.condensed(
              v-for='task in tasks'
              :variant='taskVariant(task)'
              :key="task.TaskAttributeID"
              :id="'timeline-popover-' + task.TaskAttributeID"
            )
              router-link(
                :to=`{ 
                  name: 'task-detail-overview', 
                  params: { id: task.TaskAttributeID } 
                }`
              ) 
                strong {{ task.Description }}

              b-dropdown.timeline-menu.float-right(
                v-if="!['Completed', 'Resolved'].includes(task.Status)"
                toggle-class="timeline-menu-toggle"
                right
              )
                template(#button-content)
                  svg(
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16" 
                    fill="currentColor" 
                    class="bi bi-three-dots-vertical" 
                    viewBox="0 0 16 16"
                  )
                    path(
                      d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                    )

                b-dropdown-item(
                  @click="setStatus(task, 'Completed')"
                ) Set Completed

                
              span.float-right(v-if="!['Completed', 'Resolved'].includes(task.Status)")
                template(v-if='task.CompletionDateTime') 
                  | {{ task.CompletionDateTime|dateFormat }}
                template(v-else-if='task.MinimumCompletionDateTime')
                  | {{ task.MinimumCompletionDateTime|dateFormat }} (*)
                template(v-else-if='task.CompletionMinimumIntervalFromParent')
                  | {{ task.CompletionMinimumIntervalFromParent }}
                template(v-else) -
              
              b-popover(
                :target="'timeline-popover-' + task.TaskAttributeID"
                triggers="hover"
                placement="left"
              )
                | Status: {{ task.Status }}
                br
                | Owner: {{ task.User.FullName }}
                br
                template(v-if='task.ProgressDescription') Progress: {{ task.ProgressDescription }}

        p.mt-3.mb-0 
          em (*) No due date set

  b-row(v-if='false')
    b-col(cols=6)
      b-card(header='Album data')
        pre {{ releaseFormats }}
        pre {{ albumData }}

    b-col(cols=6)
      b-card(header='TaskAttribute')
        pre {{ taskAttribute }}

    b-col(cols=6)
      b-card(header='Attribute Data')
        pre {{ taskAttribute.attributeTypes }}
</template>

<script>
import moment from 'moment'
//import numeral from 'numeral'

export default {
  components: {

  },
  props: {
    taskAttribute: Object,
  },
  data() {
    return {
      tasks: [],
      assigningUpc: false,
      creatingAlbums: false,
      creatingAlbumsProgress: '',
      sharedNotes: '',
      progressDescription: '',
      subTasksLoading: false,
    }
  },
  inject: [
    'reloadTask',
    'setTaskInstance'
  ],
  methods: {
    async setStatus(task, status) {

      this.tasks = []

      await this.$http.put(
        'task-attributes/' + task.TaskAttributeID,
        {
          Status: status
        }
      )

      this.loadTasks()

    },
    async setStatusWaitingForManufacturing() {

      const taskIds = this.tasks.filter(task => !task.Description.endsWith(' - Manufacturing') && !['Completed', 'Resolved'].includes(task.Status))
                                .map(task => task.TaskAttributeID)
      this.tasks = []
      this.subTasksLoading = true

      const promises = []
      for (const taskId of taskIds) {
        promises.push(
          this.$http.put(
            'task-attributes/' + taskId,
            {
              Status: 'Completed'
            }
          )
        )
      }

      await Promise.all(promises)
      
      const response = await this.$http.put(
        'task-attributes/' + this.taskAttribute.TaskAttributeID,
        {
          Status: 'Waiting for Manufacturing'
        }
      )

      this.setTaskInstance(response.data)
      this.loadTasks()
      this.subTasksLoading = false
    },

    async setStatusAll(tasks, status) {

      const taskIds = this.tasks.map(task => task.TaskAttributeID)
      this.tasks = []
      this.subTasksLoading = true

      const promises = []
      for (const taskId of taskIds) {
        promises.push(
          this.$http.put(
            'task-attributes/' + taskId,
            {
              Status: status
            }
          )
        )
      }

      await Promise.all(promises)
      this.loadTasks()
      this.subTasksLoading = false
    },
    async assignUpc (album) {
      this.assigningUpc = true

      try {
        await this.$http.put(
          `albums/${album.AlbumID}/assign-upc`,
        )

        this.reloadTask()
      } catch {
        this.$notify({
          type: 'error',
          text: 'An error occurred while assigning UPC. Please refresh the page and try again.'
        })
      }

      this.assigningUpc = false
    },
    taskVariant (task) {
      if (['Completed', 'Resolved'].includes(task.Status)) return 'success'

      if (
        task.CompletionDateTime && new Date(task.CompletionDateTime) - new Date() < 0
      ) {
        return 'danger'
      }

      if (
        task.MinimumCompletionDateTime && new Date(task.MinimumCompletionDateTime) - new Date() < 0
      ) {
        return 'danger'
      }

      const variants = {
        'Completed': 'success',
        'In Progress': 'primary',
        'Open': 'info',
        'On Hold': 'warning',
        'Waiting': '',
      }
      let variant = variants[task.Status]
      if (variant == undefined) variant = 'dark'

      return variant
    },
    async saveSharedNotes () {
      await this.$http.put(`task-attributes/${this.taskAttribute.TaskAttributeID}/attributes/SharedNotes`,
        { value: this.sharedNotes }
      )
      this.reloadTask()
    },
    async saveProgressDescription () {
      await this.$http.put(`task-attributes/${this.taskAttribute.TaskAttributeID}/attributes/Progress Description`,
        { value: this.progressDescription }
      )
      this.reloadTask()
    },
    async loadTasks() {
      this.subTasksLoading = true
      const response = await this.$http.get('task-attributes', {
        params: {
          perPage: 10000,
          filter: { 
            ParentTaskAttributeID: this.taskAttribute.TaskAttributeID
          }
        }
      })

      this.tasks = response.data
      this.subTasksLoading = false
    },
    dateFromNow(date) {
      if (!date) return '-'
      return moment(date).fromNow()
    },
    date(date) {
      return moment(date).format()
    },
    async createAlbums() {
      this.creatingAlbums = true

      const albumIds = []

      let index = 1
      for (const format of this.releaseFormats) {
        if (format.Album) continue
        this.creatingAlbumsProgress = `${index++}/${this.releaseFormats.length} ${format.Value}`
        let data = await this.createFormatAlbum(format)
        console.log(data)
        albumIds.push(data.AlbumID)
      }

      this.creatingAlbumsProgress = `tasks`
      await this.$http.put(
        `task-attributes/${this.taskAttribute.TaskAttributeID}/create-template-album-sub-tasks`,
        {'AlbumIDs': albumIds}
      )

      this.creatingAlbumsProgress = ''
      this.creatingAlbums = false
      this.reloadTask()
    },
    async createFormatAlbum(format) {
      const data = {...this.albumData}
      data.OriginalReleaseFormat = format.Value
      data.CatalogNr += format.Value
      delete data.TopLevel
      delete data.RecordingVersion
      delete data.MainGenre

      const url = `${process.env.VUE_APP_CE_LOCAL_URL}ApiV2/CreateAlbum`
      const headers = {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
        }

      let response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
      })
      return await response.json()
    },
  },
  computed: {
    activeTasks () {
      return this.tasks.filter(task => task.Hidden == 0)
    },
    timelineTasks () {
      return this.tasks.filter(task => { return !['Resolved'].includes(task.Status) } ) 
    },
    albumData () {

      let releaseDate = Date.parse(this.taskAttribute.attributeTypes.ReleaseDate?.Values[0].Value)
      if (isNaN(releaseDate)) {
        releaseDate = null
      } else {
        releaseDate = Math.round(releaseDate / 1000)
      }
      
      const data = {
        ProviderID: this.taskAttribute.ProviderID,
        SubProviderID: this.taskAttribute.SubProviderID,
        TermSetID: "20DBC5B0-4CD0-4DF4-B30F-5D535CFAB41D",
        TitleLanguageID: this.taskAttribute.attributeTypes.TitleLanguage?.Values[0].Value,
        Title: this.taskAttribute.attributeTypes.Title?.Values[0].Value,
        VersionTitle: this.taskAttribute.attributeTypes.VersionTitle?.Values[0].Value,
        Attribution:  this.taskAttribute.attributeTypes.Attribution?.Values[0].Value,
        CatalogNr: this.taskAttribute.attributeTypes.CatalogNumber?.Values[0].Value,
        TopLevel: this.taskAttribute.attributeTypes.TopLevel?.Values[0].Value,
        RecordingVersion: this.taskAttribute.attributeTypes.RecordingVersion?.Values[0].Value,
        MainGenre: this.taskAttribute.attributeTypes.MainGenre?.Values[0].Value,
        OriginCountry: this.taskAttribute.attributeTypes.MainGenre?.Values[0].Value,
        PLine: this.taskAttribute.attributeTypes['P-Line']?.Values[0].Value,
        CLine: this.taskAttribute.attributeTypes['C-Line']?.Values[0].Value,
        OriginalReleaseDate: releaseDate,
        ReleaseDate: releaseDate,
        TotalTrackCount: "", // Todo uit basket
        MinAccessRoleID: "0",
        PriceCode: "Mid/Front",
        Label: "Charly",
        UPCorEAN: "",
        GenerateEAN: "temp",
        ReleaseTypeID: "1",
        CountryID: "AF",
        OldIdentifier1: "",
        FreeSelection1: "",
        Notes: "",
        AgreementReference: "",
        IsParentProduct: "1",
        PrefSource: "1",
        DownloadRights: "0",
        SKU: "FFD55042-AC7C-45CE-9549-A2E1EE04BEFA",
        IDCTrackFormat: "B02C943D-34B4-4896-8A6B-091907E77E0F",
        ReviewStatus: "C617CA03-0B7E-4ED3-9C42-FB70EB28E45A",
        Artwork: [],
        ContributorRoles: [ // From tracks
          { 
            "ContributorRoleID": "8C408913-564F-480A-9179-01C308EBEB46", 
            "values": { 
              "DBF53DB7-F891-4826-8F3A-02EF1A28C46D": "Ronald  Eekhoff" 
            } 
          }
        ],
        Tags: {
          Category: { "324D4944-E8F5-4339-9731-000864B8CE50": "Audio" },
          DeliveryClass: { "D92C16F0-54F2-4002-95B8-FB1456165FA4": "Generic" },
          TopLevel: null,
          RecordingVersion: null,
          MainGenre: null,
          AlbumPriceTier: { "EBC6DAD7-0B73-47CB-8754-C945FFEFB7E7": "Mid" },
          TrackPriceTier: { "0AC30147-7974-4327-895C-94D1327218B4": "Full" },
        }
      }

      // Tags
      const tagTypes = {
        MainGenre: {"Blues": "4EAEE9D4-F805-4BC2-9DB2-00228DCDE241", "Classical": "0D0EFCC3-EFDF-493E-9CA7-0022B96C9258", "Country": "3F56D065-56C2-41F1-80E5-00242B27D499", "Disco": "BAF43DBA-D732-416C-AF65-0024439C7E86", "Electronica": "27E9A28E-43ED-427E-B146-0024FED07700", "Folk": "895E3483-71D4-4495-9A30-00252E8843C7", "Funk": "5DCDAF87-74E4-42E3-A191-00264E541CA3", "Jazz": "2BE63717-74A3-4C3D-B321-00271E87946E", "Latin": "10ABE175-DB0C-484A-903C-002791BC25CF", "Pop": "9AACEC04-71B7-4A60-BB68-002960FAD89E", "Rap Hip-Hop": "62264889-BF4B-4443-9115-002A4A0013F3", "Reggae": "F55CCD32-3C2B-48B2-A2CB-002B66C8678A", "Religious": "275BE253-B842-4D64-9108-002BA8D155FA", "Rock": "DF6A20FC-8C54-469C-9E27-002BE717FBA5", "Rock Roll": "95D76E6E-E732-43C0-9B85-002C612B47AC", "Soul": "97C3A3C5-54CC-4B70-A574-002C6C70EE8C", "Sound Effect": "C355C593-D921-43A8-A6FF-002C9BF14129", "Spoken Word": "AB56234D-2135-4D6F-A66A-002D3A9CDBC5", "Traditional Dance Music": "08E07F7E-AE33-4BF8-B9E9-002DD8F16FF1", "Traditional Rhythm Blues": "0C7046D7-1D24-4601-9F93-002E4D2FEDD5", "World": "860FE0F6-C787-42C4-9B90-002EA4E35184", "Chill Out": "B4427AD0-757C-46F8-BB9F-01B6B02A4E64", "Comedy": "41BF7C03-CC07-42B4-8385-0677B77DF0F1", "Childrens": "9C770A7B-AA50-44F1-A28B-097BA1DFD1E9", "Dance": "4A37DE2D-7F48-4CB5-9F91-6898AE94B2D9", "Singer-songwriter": "8FF04E59-4567-4322-B286-9127013597E4"},
        RecordingVersion: {"Studio Original": "21A4C982-21E5-45ED-9E49-001B186A842D", "Studio Alternate": "067200D3-6DBC-4E10-A0B8-001BC68DADFA", "Studio Re-recording": "2F6DBD9F-F4DB-42D9-AE32-001CB669EC93", "Live": "62543473-6210-4617-99E0-001D23AB8CC0", "Broadcast": "1E8A052B-145F-4DF5-B5BC-001D54B585E6", "Remix": "40347E1F-B309-4790-BF35-001D940D2DEA", "Demo": "F1C64295-BBF8-47ED-B1CA-001E0104677F", "CoverVersion": "51B5C616-DDB5-48CF-A7CE-0300C9235D85", "Karaoke": "E8C2F536-7F0B-49C2-B8F4-0300CF0E8B7B", "Instrumental": "11349E0A-126F-4B6E-810F-122A8CFF4181", "Outtake": "1FC0C103-9B67-4BEC-AF4E-15F8682F6844", "Bootleg": "E40DBC29-E08F-484E-AC77-6D925250FE97"},
        TopLevel: {"Popular": "C5FA7249-2A0E-4F6D-BB21-000A154D7FE4", "Classical": "854F7BBB-F2CD-4150-931E-000AB7309BC9", "Karaoke": "7B3259B9-72C5-41A5-954E-000B0E3E1515", "Spoken Word": "FB0286BD-8C87-4845-8671-000B47BFBA19", "Sound Effect": "6CAB9791-995A-41E4-954A-05F4ACC65738", "Production Part": "43A941CB-7686-4676-9295-39C18ACA19D5"},
      }

      for (const tagName in tagTypes) {
        data.Tags[tagName] = {}
        data.Tags[tagName][tagTypes[tagName][data[tagName]]] = data[tagName]
      }

      for (const role of data.ContributorRoles) {
        data['ContributorRole_' + role.ContributorRoleID] = JSON.stringify(role);
      }
      delete data.ContributorRoles
      
      for (const [key, value] of Object.entries(data.Tags)) {
        data['TagValue_' + key] = JSON.stringify(value)
      }
      delete data.Tags

      // Add basket id as source for tracks
      data.BasketID = this.taskAttribute.attributeTypes.BasketID?.Values[0].Value
      data.TaskAttributeID = this.taskAttribute.TaskAttributeID

      return data
    },
    releaseFormats() {
      let formats = (this.taskAttribute.attributeTypes.ReleaseFormat || { Values: [] }).Values
      formats = JSON.parse(JSON.stringify(formats))
      const missingFormats = []

      for (const album of Object.values(this.taskAttribute.Albums)) {
        const format = 
          formats.find(format => !format.Album && album.OriginalReleaseFormat == format.Value)

        if (format) {
          format.Album = album
        } else {
          missingFormats.push({
            Value: album.OriginalReleaseFormat || '<not set>',
            Album: album,
          })
        }
      }
      
      return formats.concat(missingFormats)
    },
    readyToCreateAlbums () {
      return !this.albumsCreated && this.albumDataErrors.length == 0 && this.taskAttribute.BasketID
    },
    albumDataErrors () {
      const out = []
      if (this.releaseFormats.length == 0) out.push('No Release Formats chosen')
      if (!this.albumData.SubProviderID) out.push('Sub Provider missing')
      if (!this.albumData.TitleLanguageID) out.push('Title Language missing')
      if (!this.albumData.Title) out.push('Title missing')
      
      if (!this.albumData.Attribution) out.push('Attribution missing')
      if (!this.albumData.CatalogNr) out.push('Catalog Number missing')
      if (!this.albumData.ReleaseDate) out.push('Release Date missing')
      if (!this.albumData.TopLevel) out.push('Top Level missing')
      if (!this.albumData.RecordingVersion) out.push('Recording Version missing')
      if (!this.albumData.MainGenre) out.push('Main Genre missing')
      if (!this.albumData.OriginCountry) out.push('Origin Country missing')
      console.log(out)
      
      return out
    },
    albumsCreated () {
      return this.releaseFormats.every(format => { return format.Album && format.Album.OriginalReleaseFormat == format.Value })
    },
    upcAssigned () {
      return !this.releaseFormats.some(format => {
        console.log(!format.Album, !format.Album.UPCorEAN, format.Album.UPCorEAN.startsWith('952'))
        return !format.Album || !format.Album.UPCorEAN || format.Album.UPCorEAN.startsWith('952')
      })
    },
    nextActions () {
      const actions = []
      if (!['AlbumProject', 'AlbumSimpleProject'].includes(this.taskAttribute.Template)) return []

      if (!this.taskAttribute.SubProviderID) {
        actions.push({ description: 'Assign Sub Provider to this project on the edit tab' })
      }

      if (!this.albumsCreated && !this.taskAttribute.BasketID) {
        actions.push({ description: 'Link or create a basket in the basket tab' })
      }

      if (!this.taskAttribute.CompletionDateTime) {
        actions.push({ description: 'Set a due date for this project on the edit tab' })
      }

      if (this.releaseFormats.length == 0) {
        actions.push({ description: 'Add release formats to this project on the attribute tab' })
      }

      if (!this.albumsCreated && this.readyToCreateAlbums) {
        actions.push({ description: "Create album for release formats in the 'Album information' card." })
      }

      if (!this.albumsCreated && !this.readyToCreateAlbums) {
        actions.push({ 
          description: "Finish entering all album details on the attribute tab",
          infoList: this.albumDataErrors,
        })
      }
      
      if (this.albumsCreated && !this.upcAssigned) {
        actions.push({ description: "Assign barcodes to Albums" })
      }

      //actions.push({ description: ''})

      return actions
    },
    laterActions () {
      const actions = []
      if (!['AlbumProject', 'AlbumSimpleProject'].includes(this.taskAttribute.Template)) return []

      if (!this.albumsCreated && !this.readyToCreateAlbums) {
        actions.push({ description: "Create album for release formats in the 'Album information' card." })
      }

      return actions
    },
  },
  mounted() {
    this.loadTasks()
    
    this.sharedNotes = this.taskAttribute.attributeTypes.SharedNotes?.Values[0].Value
    this.progressDescription = this.taskAttribute.ProgressDescription
  }
}
</script>

<style lang="scss">
  .timeline-menu-toggle.dropdown-toggle {
    background-color: transparent !important;
    margin-right: -20px;
    box-shadow: none !important;
  
    padding: 0 8px 0 8px;

    &::before {
      content: none !important;
    }

    &::after {
      content: none !important;
    }
  }
</style>
